@import '~shared/src/styles/mixins';
@import 'style/Variables.scss';
@import 'style/Mixins';

@font-face {
    font-family: 'Eesti';
    src: local('GT-Eesti-Display-Medium'),
        url('/fonts/GT-Eesti-Display-Medium.ttf') format('truetype'),
        url('/fonts/GT-Eesti-Display-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.MuiSnackbar-root .MuiSnackbarContent-root {
    @include large-lato-regular;
}

body {
    margin: 0;
    padding: 0;
    font-family: $font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background: $page-background;

    min-height: 100%;
}

#root {
    min-height: 100%;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.clickable {
    cursor: pointer;
}

.button-form {
    text-align: right;
}

h3 {
    margin-top: 16px;
    color: rgba(#000, 0.65);
    font-size: 14px;
    font-weight: bold;
}

.flex {
    display: flex;
}

.felx-center {
    display: flex;
    justify-content: center;
}

.flex-center-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.card-header-reverse {
    background-color: grey;
    color: white;
}

.padded-large {
    padding: 35px;
}

.bold {
    font-weight: bold;
}

.text-center {
    text-align: center;
}

.icon-big {
    font-size: 50px;
}

.appBarIcon {
    color: #101e2c;
    margin-right: 0;
    margin-left: 15px;
    border-radius: 15px;
}

.fullWidth {
    width: 100%;
}

.table-component {
    width: 100%;

    .MuiTableCell-head {
        padding-bottom: 0;
        padding-top: 0;
        height: 47px;
    }

    &.adjust {
        > .MuiTableContainer-root {
            .MuiPaper-root {
                width: -moz-fit-content;
                width: fit-content;
            }
        }
    }

    .MuiTableSortLabel-root.MuiTableSortLabel-active {
        color: #57616bcc;
    }
    .tbody {
        position: relative;
        border-top: 5px solid #efefef;
    }

    .table-pagination-caption {
        font-family: Lato, sans-serif;
        font-size: 14px;
        font-weight: normal;
    }
    .table-pagination-select {
        font-family: Lato, sans-serif;
        font-size: 14px;
        font-weight: normal;
    }

    .table-component-header {
        color: #57616bcc;
        font-family: Eesti, sans-serif;
        font-weight: lighter;
        font-size: 12px;
        text-transform: uppercase;
    }
}
