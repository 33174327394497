@import 'style/Variables.scss';

// Layout

// Variables
$layout-header-background: $header-background;
$layout-header-color: $header-color;
$layout-header-height: 64px;

$layout-header-border: $light-gray;
$layout-content-background: none;
$layout-footer-background: $header-background;
$layout-footer-border: $light-gray;

body {
    background-color: #E5E9E9;
}

.MuiToolbar-regular {
    height: 64px;
}

.main-content {
    width: 100%;
}

.main-content > *:not(.list-page) {
    max-width: 1200px;
    width: 100%;
}

.list-page {
    max-width: 100%;
    width: 100%;
}