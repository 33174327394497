@import "~shared/src/styles/mixins";

.app-version{
  position: absolute;
  bottom: 2px;
  @include small-lato-regular;
  color: grey;
  width: 95%;
  margin: auto;
  overflow: hidden;
  text-align: center;
}