.search-item {
  font-family: Lato, sans-serif;
  font-weight: bold;
  background-color: #FFFFFF;
  padding: 12px;
  color: #000000DE;
  align-items: center;

  & > * {
    align-items: center;
    display: flex;
  }

  &:hover {
    background-color: #DDDFE1;
  }
  .type {
    font-size: 10px;
    text-transform: uppercase;
  }
  .reference {
    font-size: 16px;
  }
  .details {
    font-weight: normal;
    font-size: 14px;
    text-align: right;
  }
}