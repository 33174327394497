@mixin large-lato-regular {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: normal;
}

@mixin very-large-lato-bold {
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: bold;
}

@mixin large-lato-bold {
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: bold;
}

@mixin normal-lato-regular {
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: normal;
}

@mixin normal-lato-bold {
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: bold;
}

@mixin small-lato-regular {
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: normal;
}

@mixin small-lato-medium {
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 500;
}

@mixin small-lato-bold {
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: bold;
}

@mixin regular-lato-bold {
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: bold;
}

@mixin lato-title-h2 {
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-weight: bold;
}

@mixin large-eesti-medium {
  font-family: Eesti, sans-serif;
  font-size: 16px;
  font-weight: 500;
}


@mixin very-large-eesti-medium {
  font-family: Eesti, sans-serif;
  font-size: 18px;
  font-weight: 500;
}

@mixin eesti-title-h1 {
  font-family: Eesti, sans-serif;
  font-size: 30px;
  font-weight: 500;
}

@mixin eesti-title-h3 {
  font-family: Eesti, sans-serif;
  font-size: 20px;
  font-weight: 500;
}

@mixin normal-eesti-medium {
  font-family: Eesti, sans-serif;
  font-size: 14px;
  font-weight: 500;
}


@mixin small-eesti-medium {
  font-family: Eesti, sans-serif;
  font-size: 12px;
  font-weight: 500;
}