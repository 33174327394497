.error-page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .error-container {
    display: flex;
    width: 33%;
    justify-content: center;
    align-items: center;
    height: 30%;
    font-size: 18px;
    font-family: Lato, sans-serif;
    font-weight: bold;
    border-radius: 8px;
    background-color: white;
  }
}