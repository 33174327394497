.notification-button {
  border-radius: 10px !important;

  .notification-badge{
    .MuiBadge-colorPrimary{
      color: white;
    }
  }

  &:hover {
    background-color: rgba(0,0,0,0.07) !important;
  }
  
  &.opened {
    background-color: rgba(0,0,0,0.07) !important;
    color: rgba(0, 183, 150, 1);

    .bell-opened {
      filter: brightness(0) saturate(100%) invert(54%) sepia(25%) saturate(5286%) hue-rotate(134deg) brightness(94%) contrast(101%);
    }
  }
}