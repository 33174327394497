.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.Mui-selected::before {

  content: "";
  background-clip: content-box;
  border-radius: 10px;
  position: absolute;
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  top: 12px;
  left: 12px;
}


.menu-list {
  margin-top: 20px !important;
  overflow: auto;
  margin-bottom: 67px !important;

  .menu-list-item {
    font-family: 'Eesti', sans-serif !important;
    height: 45px;
    margin-top: 5px;
    padding-top: 1px;
    padding-bottom: 0px;
    margin-left: 12px;
    border-radius: 10px;
    width: calc(100% - 24px);

    &.closed{
      .menu-icon {
        margin-left: -3px;
      }
      .menu-text {
        max-width: 0;
      }
    }

    .menu-icon {
      padding-top: 4px;
      transition: all 0.2s ease-in-out;
    }

    .menu-container{
      display: flex;
      align-items: center;
      height: 100%;
    }
    .menu-text {
      color: #798188;
      transition: all 0.2s;
      font-family: 'Eesti', sans-serif;
      font-size: 16px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 500px;
      margin-left: 17px;
      &.selected {
        color: #ffffff !important;
      }
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.12) !important;

      .menu-text {
        color: #ffffff !important;
      }
    }

    &.selected {
      background-color: rgba(255, 255, 255, 0.12) !important;
    }
  }
}


.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
  position: relative;
}