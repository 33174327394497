$color-hover: white !important;
$color-default: #B7BCC0;
$transition-color: ease-in 0.5s !important;

.menu-arrow-container {

  border-top: 1px solid #1b2836;
  border-radius: 0 !important;
  width: 100%;
  overflow: hidden !important;
  margin-left: 0 !important;
  cursor: pointer !important;

  position: absolute !important;
  top: 0;
  bottom: 0;


  .menu-arrow-block {

    &.closed .closed {
      opacity: 0;
    }

    .menu-arrow {
      transition: $transition-color;
      color: $color-default;
      position: relative;

      .menu-arrow-button {
        position: absolute;
        left: 0.9rem;
        top: 0.28rem;
        transform: scale(0.85);
      }
      .menu-arrow-button-outline {
        position: absolute;
        left: 0.53rem;
        top: -1px;
        transform: scale(0.8);
      }
    }

    .text {
      transition: $transition-color;
      color: $color-default;
      font-size: 11pt;
    }
  }
  & > .MuiIconButton-label {
    display: block;
  }

  &:hover {
    .menu-arrow {
      color: $color-hover;
    }

    .text {
      color: $color-hover;
    }
  }

}
