@import "~shared/src/styles/mixins";

.notification {
  padding: 0px 23px 0px 20px !important;

  height: 100%;

  &.inactive {
    @include normal-lato-regular;
  }
  &.active {
    @include regular-lato-bold;
  }

  .notification-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 650px;

    & > div:first-child {
      display: flex;
      align-items: center;
      gap: 10px;
      max-width: 450px;
    }

    .notification__target-reference {
      grid-column: 1;
    }

    .notification__description {
      grid-column: 2;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #101e2c;
      font-size: 14px;
      margin: 3px 0;
    }

    .notification__date {
      grid-column: 3;
      color: #57616bcc;
      @include small-lato-regular;
    }
    .MuiGrid-item {
      display: flex;
      align-items: center;
    }
  }
}
