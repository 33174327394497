#menu-notifications .MuiList-padding {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.app-bar {
  padding-left: 0 !important;

  .MuiGrid-container {
    height: 100%;
  }



  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;

    &.closed {
      width: 73px;
      flex-basis: initial;
    }

    .desktop {
      display: flex;
    }

    .mini {
      display: none;
    }
  }

  .search-container {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  .profile-container, .buttons-container {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .MuiGrid-item {
    height: 100%
  }

  @media (max-width: 960px) {
    .logo-container {
      .desktop {
        display: none;
      }

      .mini {
        display: flex;
      }
    }
  }

}