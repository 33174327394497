@import "~shared/src/styles/mixins";

.username-field {
  .MuiFormLabel-root{
    @include large-lato-regular;
  }
  .MuiInputLabel-animated {
    z-index: 1;
  }
  .username-field-input {
    @include large-lato-regular;
  }
}