@import "style/Variables.scss";

.login-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;

  .layout-panel {
    width: 300px;
    background: lighten($black, 10%);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
    padding-top: 24px;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 160px;
    height: 160px;
    border-radius: 50%;

    img {
      width: 100%;
    }
  }
}
